import React, { useState } from 'react';
import ReactPlayer from "react-player/youtube";

function App() {
  return (
    <div class="container">
      <ReactPlayer
        url="https://youtu.be/O96W1xkI6jo"
        width="100%"
        height="300px"
      />
      <h1>Jaz Gulati</h1>
      <p>Updated <time datetime="2024-10-05">January 26, 2025</time></p>
      <h2>About me:</h2>
      <ul>
        <li>Enjoyer of sounds</li>
        <li>Founder of <a href="https://grassroots.studio">Grassroots Studio</a>, a software company
          <ul>
            <li>Now: Making Web3 friendly at <a href="https://catalog.fi">Catalog</a> and <a href="https://garden.finance">Garden</a></li>
            <li>Past: Worked on <a href="https://renprotocol.org">Ren</a> (acquired by <Tooltip text="FTX" tooltipText="🥴" />), a protocol that enables the movement of value between blockchains</li>
            <li>Past: Built a wagering platform with <Tooltip text="3AC" tooltipText="😵‍💫" /></li>
          </ul>
        </li>
      </ul>
      <h2>Current rotation:</h2>
      <ul>
        <li>454 - Casts of a Dreamer <span>(Cosmic Rap, 2024)</span></li>
        <li>Anysia Kym - Truest <span>(Electronic, 2024)</span></li>
        <li>Chicken P - Chick James, Vol. 1 <span>(Detroit Trap, 2024)</span></li>
        <li>Genshin - Luv Sounds <span>(Contemporary R&B, 2024)</span></li>
        <li>Knxwledge - WT.22 <span>(Rap Flips, 2024)</span></li>
        <li>Gucci Mane - I'm Up <span>(Trap, 2012)</span></li>
        <li>corto.alto - 30​&#47;​108 <span>(Jazz Fusion, 2024)</span></li>
        <li>DJ Screw - The Legend <span>(Chopped and Screwed, 2001)</span></li>
        <li>Chief Keef - The Leek, Vol. 1<span>(Chicago Drill, 2015)</span></li>
        <li>Doja Cat - Scarlet 2 CLAUDE <span>(Pop Rap, 2024)</span></li>
      </ul>
      <h2>Reading list:</h2>
      <ul>
        <li>David Deutsch - The Beginning of Infinity</li>
        <li>Leopold Aschenbrenner - Situational Awareness</li>
        <li>Will Larson - An Elegant Puzzle</li>
        <li>Charles T. Munger - Poor Charlie's Almanack</li>
        <li>Clayton Christensen - The Innovator's Dilemma</li>
        <li>W. Chan Kim & Renée Mauborgne - Blue Ocean Strategy</li>
        <li>Donella Meadows - Thinking in Systems</li>
        <li>Shoshana Zuboff - The Age of Surveillance Capitalism</li>
        <li>Azeem Azhar - The Exponential Age</li>
        <li>Jiddu Krishnamurti - Think on These Things</li>
      </ul>
    </div>
  );
}

const Tooltip = ({ text, tooltipText }) => {
  const [visible, setVisible] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
    setVisible(true);
  };

  const handleMouseLeave = () => {
    setVisible(false);
  };

  const handleMouseMove = (e) => {
    setPosition({ x: e.clientX, y: e.clientY });
  };

  return (
    <div
      className="tooltipText"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onMouseMove={handleMouseMove}
    >
      {text}
      {visible && (
        <div
          className="tooltip"
          style={{ top: position.y, left: position.x, transform: 'translate(-50%, -120%)' }}
        >
          {tooltipText}
        </div>
      )}
    </div>
  );
};

export default App;
